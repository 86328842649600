import { FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import { Answer, Question } from 'components/appointments/types'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import FileUploader from 'components/shared/file-uploader'
import TemporalFile from 'components/shipper/components/temporalFile'
import { fancyToast } from 'components/utils'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'
import { QuestionPermission } from '../../../models/QuestionPermission'
import { AnswerFile } from 'components/appointments/shared/answer-file'
import { getFileName } from 'components/utils/parse-filename'

const Document = ({
  question,
  disabled,
  updateAnswer,
  label,
  permission,
  answer
}: {
  question: Question
  answer: Answer
  onSuccess?: (signedIds: []) => void
  disabled: boolean
  updateAnswer: (answer: any) => void
  label: string
  permission: Partial<QuestionPermission>
}) => {
  const { caption, id } = question
  const [previewData, setPreviewData] = useState<any>(null)
  const {
    temporalFiles,
    documentSelections,
    actions: { removeTemporalFile, addFileSelection }
  } = useCQStateValue()
  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  const labelStyles = css({
    position: 'absolute',
    paddingLeft: '20px',
    paddingTop: '18px',
    color: '#04112B',
    fontSize: '11px',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '12px',
    opacity: 0.6,
    zIndex: 1,
    transition: '.3s all ease-in-out',
    boxSizing: 'border-box',
    height: theme.sizing.scale1200
  })

  useEffect(() => {
    const updatedPreviewData = temporalFiles.filter(tf => tf?.questionId === id)[0]?.files
    setPreviewData(updatedPreviewData)
    updateAnswer({
      id: answer.id,
      questionId: id,
      response: updatedPreviewData ? 'File updated' : '',
      error: permission?.required && !updatedPreviewData
    })
  }, [id, temporalFiles])

  const handleOnRemove = e => {
    updateAnswer({
      questionId: id,
      response: '',
      error: permission?.required
    })
    removeTemporalFile(id, e.id)
  }

  const labelWithAsterisk = permission?.required && label ? `* ${label}` : label

  return (
    <FormControl caption={caption}>
      <FlexGridItem position="relative">
        {Array.isArray(answer.attachedDocuments) &&
          answer.attachedDocuments.length > 0 &&
          answer.attachedDocuments?.map(
            item =>
              item && (
                <AnswerFile
                  props={{
                    url: item?.documentSecureUrl,
                    name: getFileName(item?.documentSecureUrl).replace(/%20/g, ' '),
                    id: id
                  }}
                  onRemoveFile={() => {
                    addFileSelection(item?.id, answer?.id)
                  }}
                  selectedForRemove={
                    documentSelections.find(selectedItem => selectedItem?.documentId === item?.id)
                      ? true
                      : false
                  }
                  disabled={!permission?.canEdit || disabled}
                />
              )
          )}
        {previewData?.length > 0 &&
          previewData?.map(
            item => item && <TemporalFile file={item?.file || item} onRemoveFile={handleOnRemove} />
          )}
        {labelWithAsterisk && (
          <label
            className={labelStyles}
            htmlFor={labelWithAsterisk ? labelWithAsterisk.replace(/\s+/g, '-').toLowerCase() : ''}>
            {labelWithAsterisk}
          </label>
        )}
        <FileUploader
          disabled={disabled}
          questionId={id}
          multiple
          onError={() => fancyToast({ info: 'Error uploading file, try again later' }, 412)}
          options={{
            addFile: true,
            type: 'document',
            customLabel: t('Common.FileUploader.Label.Text')
          }}
        />
      </FlexGridItem>
    </FormControl>
  )
}

export default Document
