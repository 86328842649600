import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { FlexGrid } from 'baseui/flex-grid'
import { Question } from 'components/appointments/types'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import QuestionsList from './QuestionsList'
import { CurrentUserContext } from '../../../homepage/current-user-context'
import { getQuestionPermissions } from '../../../utils/get-question-permissions'

interface AdditionalInformationProps {
  appointment?: any
  appointmentType: any
  userType: string
  finishButtonLabel?: string
  loading?: boolean
  onSaveTemporalFiles?: boolean
  setPreparedDataToUpload?: any
  checkFileUploaderPresence?: any
  customQuestionsContext?: any
  afterSubmit?: Function
  setAppointment?: Function
  showErrors?: boolean
}

const AdditionalInformation = (props: AdditionalInformationProps) => {
  const { appointment, appointmentType, setAppointment, showErrors, userType } = props
  const { currentUser } = useContext(CurrentUserContext)
  const {
    actions: { setQuestions, setAnswers }
  } = useCQStateValue()

  const questions = useMemo(() => {
    return (
      appointmentType?.questions?.filter(question => {
        const { equipmentTypeIds } = question
        if (question.disabled) {
          return false
        }
        return equipmentTypeIds?.includes(appointment.equipmentTypeId)
      }) || []
    )
  }, [appointmentType, appointment.equipmentTypeId])

  const checkQuestionError = question => {
    const permissions = getQuestionPermissions({
      currentUser,
      userType,
      question
    })

    return permissions ? permissions.required : true
  }

  const loadQuestions = useCallback(() => {
    setQuestions(questions)
    const answers = questions.map((question: Question) => {
      const existingAnswer = appointment?.answersAttributes?.find(
        response => response.questionId === question.id
      )

      return {
        attachedDocuments: existingAnswer ? existingAnswer.attachedDocuments : [],
        response: existingAnswer ? existingAnswer.response : '',
        id: existingAnswer ? existingAnswer.id : null,
        questionId: question.id,
        error: checkQuestionError(question)
      }
    })
    setAnswers(answers)
    setAppointment?.(answers)
  }, [questions])

  useEffect(() => {
    loadQuestions()
  }, [questions, loadQuestions])

  return (
    <FlexGrid flexGridRowGap="scale200">
      {questions && questions.length > 0 ? (
        <QuestionsList
          {...{
            appointment,
            setAppointment,
            showErrors,
            userType
          }}
        />
      ) : null}
    </FlexGrid>
  )
}

export default AdditionalInformation
