import React, { useContext, useEffect, useState } from 'react'
import { uploadFile } from 'components/utils/authenticated-fetch'
import { Modal, ModalBody, ModalFooter, ModalHeader, SIZE } from 'baseui/modal'
import Datepicker from 'components/ui/generic/DatePicker'
import FormControl from 'components/ui/generic/FormControl'
import Select from 'components/ui/generic/Select'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { RefreshSearchContext } from 'cyber/search/search'
import { Controller, useForm } from 'react-hook-form'
import { sortBy } from 'lodash'
import { fancyToast } from 'components/utils'
import { StatusCodes } from 'components/constants/http-status-codes'
import { DateTime } from 'luxon'
import { Info } from '@phosphor-icons/react'
import { Block } from 'baseui/block'
import { useCalendarSelectedOptionsContext } from '../../../../contexts/calendar-selected-options.context'
import { searchService } from '../../../../services/search.service'
import { InfoBoxContainer, InfoBoxUnorderList, InfoBoxWrapper } from './BulkUploadModal.styled'
import { FileNameLabel } from '../styled/TemporalFile.styled'
import ErrorMessageButton from '../../../../shared/error-message-button'
import BulkFileUploader from './BulkFileUploader'
import { useTranslation } from 'react-i18next'
import { APPOINTMENT_TYPE_ALL_OPTION } from '../create-appointment-modal'
import { DROP, LIVE } from 'components/constants/handling_method'
import { capitalize } from 'lodash'
import { INBOUND_FLAG, OUTBOUND_FLAG } from 'components/constants/appointment_types'
import { CurrentUserContext } from '../../../../homepage/current-user-context'

const handlingMethods = [
  {
    name: capitalize(LIVE),
    id: LIVE
  },
  {
    name: capitalize(DROP),
    id: DROP
  }
]

const AppointmentBulkForm = ({ showBulkModal, onClose, selectedFacilities }) => {
  const [file, setFile] = useState(null)
  const { refresh } = useContext(RefreshSearchContext)
  const [facilityOptions, setFacilityOptions] = useState([])
  const [appTypeOptions, setAppTypeOptions] = useState([])
  const [handlingMethodOptions, setHandlingMethodOptions] = useState(handlingMethods)
  const [selectedHandlingMethod, setSelectedHandlingMethod] = useState([handlingMethods[0]])
  const { calendarSelectedOptionsState } = useCalendarSelectedOptionsContext()
  const [uploadButtonHasBeenClicked, setUploadButtonHasBeenClicked] = useState(false)
  const { currentUser } = useContext(CurrentUserContext)

  const { control, handleSubmit, setValue, watch, getValues } = useForm({
    defaultValues: {
      date: new Date(),
      facility: [],
      appType: [],
      handlingMethod: []
    }
  })

  const { t } = useTranslation()

  useEffect(() => {
    setValue('date', calendarSelectedOptionsState.date)
  }, [calendarSelectedOptionsState])

  useEffect(() => {
    if (selectedFacilities && selectedFacilities[0]) {
      setValue('facility', selectedFacilities[0])
    }
  }, [selectedFacilities])

  useEffect(() => {
    searchService.search('facility', '').then(({ hits }) => {
      const sortedHits = sortBy(hits, 'createdAt')
      setFacilityOptions(sortedHits)
    })

    searchService.search('appointment_type', '').then(({ hits }) => {
      const sortedHits = sortBy(hits, 'createdAt')
      setAppTypeOptions(sortedHits)
    })
  }, [])

  useEffect(() => {
    setValue('appType', null)
  }, [selectedHandlingMethod])

  const onModalClose = () => {
    setFile(null)
    onClose()
  }

  const onSubmit = ({ date, appType, facility }) => {
    setUploadButtonHasBeenClicked(true)
    const formData = new FormData()
    const dateUTC = DateTime.utc(date.getFullYear(), date.getMonth() + 1, date.getDate())

    formData.append('file', file, file.name)
    formData.append('appointment_type_id', appType[0]?.id)
    formData.append('facility_id', facility?.id)
    formData.append('date', dateUTC.toISODate())
    formData.append('handling_method', selectedHandlingMethod[0]?.id)

    uploadFile({
      path: '/appointments/bulk_upload.json',
      method: 'POST',
      body: formData
    }).finally(() => {
      fancyToast({ info: 'File processed.' }, StatusCodes.OK)
      setUploadButtonHasBeenClicked(false)
      onModalClose()
      refresh()
    })
  }

  const onFileChange = (accepted, _rejected) => {
    setFile(accepted[0])
  }

  const uploadFormErrors = () => [
    {
      label: 'Type of Appointment must be selected.',
      status: !!watch('appType')?.length
    },
    { label: 'File must be uploaded.', status: !!file },
    {
      label: 'Date must be present.',
      status: !!watch('date')
    }
  ]

  const getAppointmentTypesFiltered = () => {
    let options = appTypeOptions

    if (selectedHandlingMethod.length > 0 && selectedHandlingMethod[0].id === DROP) {
      options = appTypeOptions.filter(
        apptype => apptype.type == INBOUND_FLAG || apptype.type == OUTBOUND_FLAG
      )
    }
    return options
  }

  return (
    <Modal
      isOpen={showBulkModal}
      onClose={onModalClose}
      size={SIZE.auto}
      unstable_ModalBackdropScroll>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>{t('Appointments.BulkModal.Title')}</ModalHeader>
        <ModalBody>
          <Block maxWidth="620px" display="flex" flexDirection="column" gridRowGap="scale400">
            <Block
              display="flex"
              flexDirection={['column', 'column', 'row']}
              gridColumnGap="scale800"
              gridRowGap="scale400">
              <FlexGrid>
                <div
                  style={{
                    maxWidth: 0,
                    maxHeight: 0,
                    overflow: 'hidden'
                  }}>
                  <input autoFocus />
                </div>
                <FlexGridItem>
                  <FormControl label="Date">
                    <Controller
                      name="date"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Datepicker
                          {...field}
                          formatString="MM/dd/yyyy"
                          onChange={({ date }) => setValue('date', date as Date)}
                        />
                      )}
                    />
                  </FormControl>
                </FlexGridItem>
                <FlexGridItem>
                  <FormControl label="Facility">
                    <Controller
                      name="facility"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          onChange={e => {
                            field.onChange(e.value[0])
                          }}
                          controlRef={ref}
                          placeholder=""
                          options={facilityOptions}
                          labelKey="name"
                          clearable={false}
                          deleteRemoves={false}
                        />
                      )}
                    />
                  </FormControl>
                </FlexGridItem>
                {currentUser?.yardManagementActive && (
                  <FlexGridItem>
                    <FormControl label="Handling method">
                      <Select
                        value={selectedHandlingMethod}
                        onChange={e => {
                          setSelectedHandlingMethod(e.value)
                        }}
                        placeholder=""
                        options={handlingMethodOptions}
                        labelKey="name"
                        clearable={false}
                        deleteRemoves={false}
                      />
                    </FormControl>
                  </FlexGridItem>
                )}

                <FlexGridItem>
                  <FormControl label="Type of Appointment">
                    <Controller
                      name="appType"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          onChange={e => {
                            field.onChange(e.value)
                          }}
                          controlRef={ref}
                          placeholder=""
                          options={getAppointmentTypesFiltered()}
                          labelKey="name"
                          clearable={false}
                          deleteRemoves={false}
                        />
                      )}
                    />
                  </FormControl>
                </FlexGridItem>
              </FlexGrid>
              <InfoBox />
            </Block>

            {file?.name && <FileNameLabel>{file.name}</FileNameLabel>}
            <FormControl label={t('Appointments.BulkModal.Title')}>
              <BulkFileUploader onFileChange={onFileChange} />
            </FormControl>
          </Block>
        </ModalBody>
        <ModalFooter>
          <ErrorMessageButton
            label="Upload"
            disabled={uploadButtonHasBeenClicked}
            errors={uploadFormErrors()}
            buttonProps={{
              type: 'submit'
            }}
          />
        </ModalFooter>
      </form>
    </Modal>
  )
}

const InfoBox = () => (
  <InfoBoxWrapper>
    <InfoBoxContainer>
      <Info size={16} />
      <InfoBoxUnorderList>
        <li>A Time has to be in HH:MM 24hr format</li>
        <li>100 records per file</li>
        <li>Date should be in mm/dd/yy format</li>
        <li>If time is not in the correct format, the appointment will be created for 12 am </li>
      </InfoBoxUnorderList>
    </InfoBoxContainer>
  </InfoBoxWrapper>
)

export default AppointmentBulkForm
