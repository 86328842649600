import React from 'react'
import styled from 'styled-components'

const PopoverWrapper = styled.div`
  position: relative;
`

const PopoverContent = styled.div`
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transform: translateY(${props => (props.isOpen ? '0' : '20px')});
  transition: opacity 0.3s ease-in-out ${props => (props.isOpen ? '0.3s' : '0s')},
    transform 0.3s ease-in-out ${props => (props.isOpen ? '0.3s' : '0s')};
  position: absolute;
  pointer-events: ${props => (props.isOpen ? 'auto' : 'none')};
  background-color: #fff;
  padding: 16px;
  min-width: 284px;
  border-radius: 24px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 8px;
  z-index: 10;
  left: 0;
  top: 45px;
`

const PopoverOnClickCloseZone = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 2;
`

const CalendarPopover = ({ isOpen, togglePopover, children }) => {
  return (
    <PopoverWrapper>
      <PopoverContent data-testid="popover-content" isOpen={isOpen}>
        {children}
      </PopoverContent>
      {isOpen && (
        <PopoverOnClickCloseZone
          data-testid="close-zone"
          onClick={() => togglePopover(prevState => !prevState)}
        />
      )}
    </PopoverWrapper>
  )
}

export default CalendarPopover
