import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// context imports
import { CurrentUserContext } from 'components/homepage/current-user-context'
import EquipmentTypeWithCapacity from 'components/components/EquipmentType/EquipmentTypeWithCapacity'
import CarrierSelect from 'components/facilities/steps/fields/carrier-select'

import { useAppointmentContext } from 'components/contexts/appointment-context'
import AdditionalInformation from 'components/facilities/book/additional-information'
import AppointmentTypeSelect from 'components/pages/CreateAppointmentForExternalUsers/fields/AppointmentTypeSelect'
import { RowGap } from 'components/pages/CreateAppointmentForExternalUsers/CreateAppointmentReusableForm'
import SchedulerSelect from 'components/pages/CreateAppointmentForExternalUsers/fields/SchedulerSelect'
import TrailerStatusSection from 'components/pages/CreateAppointmentForExternalUsers/TrailerStatusSection'
import UniqueIdentifiers from 'components/pages/CreateAppointmentForExternalUsers/fields/UniqueIndentifiersInput'
import { DROP } from 'components/constants/handling_method'
import { useLocalStorage } from 'react-use'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import { useFileHandler } from 'components/hooks/use-file-handler'

const AppointmentForm = ({
  appointmentDirection: title,
  appointment,
  setAppointment,
  facility
}) => {
  const { t } = useTranslation()

  const { currentUser } = useContext(CurrentUserContext)
  const { updateFiles, onFilesUploaded } = useFileHandler()
  const appointmentDirection = title.toLowerCase()

  const {
    state: {
      appointments,
      handlingMethod,
      appointmentId,
      recurring: { checked: isRecurring },
      uploadFiles
    },
    key
  } = useAppointmentContext()

  const {
    temporalFiles,
    documentSelections,
    actions: { updateFileUploadState }
  } = useCQStateValue()

  const [cacheSelectedAppointmentType, setCacheSelectedAppointmentType] = useLocalStorage(
    `DASHBOARD_APPOINTMENT_TYPE_BY_USER_AND_ORG`,
    undefined
  )

  useEffect(() => {
    if (cacheSelectedAppointmentType?.[currentUser.shipperId]?.[currentUser.id]) {
      setAppointment(
        'appointmentTypeId',
        cacheSelectedAppointmentType?.[currentUser.shipperId]?.[currentUser.id]
      )
    }
  }, [cacheSelectedAppointmentType])

  //trigger the files update(delete or upload)
  useEffect(() => {
    updateFiles({
      documentSelections,
      uploadFiles,
      temporalFiles,
      updateFileUploadState,
      onComplete: () => {
        setAppointment('filesUploaded', true)
      }
    })
  }, [uploadFiles])

  //check if the file update is complete
  useEffect(() => {
    onFilesUploaded({
      temporalFiles,
      uploadFiles,
      onComplete: () => {
        setAppointment('filesUploaded', true)
      }
    })
  }, [temporalFiles, uploadFiles])

  const appointmentKey = `${key}-${title}`

  const appointmentTypeIdSelected = appointments[appointmentDirection].appointmentTypeId
  const appointmentTypeSelected = facility.appointmentTypes?.find(
    appointmentType => appointmentType.id === appointmentTypeIdSelected
  )
  const appointmentTypeUniqueIdentifierCustomLabel =
    appointmentTypeSelected?.customLabels?.purchaseOrderIdentifiers || ''
  const appointmentTypeCustomCustomerLabel = appointmentTypeSelected?.customLabels?.scheduler || ''

  return (
    <>
      {/** APPOINTMENT TYPE */}
      <AppointmentTypeSelect
        disabled={appointmentId ? true : false}
        appointmentDirection={title}
        onChange={value => setAppointment('appointmentTypeId', value.option.id)}
        appointmentTypes={facility.appointmentTypes}
      />
      <RowGap />
      {/** EQUIPMENT TYPE */}
      {appointment?.appointmentTypeId &&
        facility?.appointmentPreference &&
        facility?.appointmentPreference?.dockCapacitiesAttributes?.length > 0 && (
          <EquipmentTypeWithCapacity
            isInternal={true}
            record={appointment}
            setRecord={value => setAppointment('equipmentTypeId', value.equipmentTypeId)}
            dockCapacitiesAttributes={facility?.appointmentPreference?.dockCapacitiesAttributes}
            equipmentCapacityShared={facility?.appointmentPreference?.equipmentCapacityShared}
            label={
              handlingMethod === DROP && title
                ? t('Appointments.CreateAppointmentModal.Fields.EquipmentType.Text', {
                    type: title
                  })
                : t('Appointments.CreateAppointmentModal.Fields.EquipmentType.Label')
            }
            recordAttributeName="equipmentTypeId"
          />
        )}
      {/** TRAILER, STATUS, STATEs */}
      {handlingMethod === DROP && (
        <TrailerStatusSection
          title={title}
          appointment={appointment}
          setAppointment={setAppointment}
          appointmentDirection={appointmentDirection}
          appointmentKey={appointmentKey}
          oneColumn
        />
      )}

      <RowGap />
      {/** PURCHASE ORDER UNIQUE IDENTIFIERS */}

      <UniqueIdentifiers
        onChange={value => setAppointment('purchaseOrdersAttributes', value)}
        appointment={appointment}
        key={'0'}
        currentUser={currentUser}
        label={appointmentTypeUniqueIdentifierCustomLabel}
        direction={title}
        required={!isRecurring}
      />
      <RowGap />
      {/** THIS IS THE VENDOR/CUSTOMER, PREVIOUS DEV NAMED IT -SCHEDULERSELECT-  */}
      <SchedulerSelect
        appointment={appointment}
        setAppointment={value => setAppointment('schedulerId', value)}
        key={appointmentKey}
        label={appointmentTypeCustomCustomerLabel}
      />
      <RowGap />
      <CarrierSelect
        schedulerId={currentUser.schedulerId}
        appointment={appointment}
        setAppointment={value => setAppointment('carrierId', value.carrierId)}
        createdAsOther={true}
      />
      <RowGap />
      <AdditionalInformation
        appointment={appointment}
        setAppointment={value => setAppointment('answersAttributes', value)}
        userType={currentUser && currentUser.userType}
        appointmentType={appointmentTypeSelected}
        showErrors={false}
      />
    </>
  )
}

export default AppointmentForm
