import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Block, BlockProps } from 'baseui/block'
import { LabelLarge, ParagraphXSmall } from 'baseui/typography'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import ButtonGroup from '../../components/ButtonGroup'
import Divider from '../../ui/generic/Divider'
import VButton from '../../ui/generic/Button'
import Checkbox from '../../ui/generic/Checkbox'
import { Check, X } from '@phosphor-icons/react'
import SchedulerEmail from 'components/facilities/steps/fields/scheduler-email'
import FacilitySelect from '../../facilities/steps/fields/facility-select'
import AppointmentForm from './../CreateAppointmentForExternalUsers/AppointmentForm'
import { CREATE, DateTime } from './../CreateAppointmentForExternalUsers/DateTime'
import {
  INBOUND,
  IS_DROP,
  OUTBOUND,
  useAppointmentContext
} from 'components/contexts/appointment-context'
import { useFacilityContext } from 'components/contexts/facility-context'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import { INBOUND_TYPE, OUTBOUND_TYPE } from 'components/constants/appointment_types'
import ErrorMessageButton from 'components/shared/error-message-button'
import TermsOfServiceModal from './../CreateAppointmentForExternalUsers/TermsOfServiceModal/TermsOfServiceModal'
import { useCarriersContext } from 'components/contexts/carriers.context'
import { handlingMethodButtons } from 'components/shared/buttons/liveDropButtons'
import { appointmentTypesButtons } from 'components/shared/buttons/appointmentTypeButtons'
import regexValidations from '../../utils/regex-validations'

const itemProps: BlockProps = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start'
}

const OpenSchedulingCreateAppointmentDrop: React.FC = () => {
  const { state, actions } = useAppointmentContext()
  const {
    actions: { setIsVendorSelectDisabled }
  } = useCarriersContext()

  const {
    loading,
    handlingMethod,
    appointmentDirections,
    schedulerEmail,
    createAppointmentButtonDisableFlags,
    termsOfService,
    uploadFiles,
    uploadedFiles
  } = state

  const {
    setHandlingMethod,
    setAppointmentDirection,
    setSchedulerEmail,
    createAppointmentOpenScheduling,
    resetAppointmentStates,
    setShowTermsOfServiceModal,
    shouldDisableCancelButton,
    setUploadFiles
  } = actions

  const { isCreateAppointmentButtonDisabled, errors } = createAppointmentButtonDisableFlags

  const { state: facilityState } = useFacilityContext()
  const { facility } = facilityState

  const { t } = useTranslation()

  const is_inbound = appointmentDirections.includes(INBOUND)
  const is_outbound = appointmentDirections.includes(OUTBOUND)
  const hasHandlingMethod = handlingMethod !== ''

  const returnErrors = () => {
    const deafultErrors = errors.map(error => ({ label: error.label, status: !error.status }))
    deafultErrors.push({
      label: t('Scheduler.OpenScheduling.Validations.Errors.Scheduler'),
      status: !!schedulerEmail?.email
    })

    if (schedulerEmail?.email && !regexValidations.email.test(schedulerEmail?.email)) {
      deafultErrors.push({
        label: t('Scheduler.OpenScheduling.Validations.Errors.SchedulerInvalid'),
        status: false
      })
    }

    return deafultErrors
  }

  useEffect(() => {
    setIsVendorSelectDisabled?.(true)
  }, [])

  const prepareAppointment = () => {
    setUploadFiles(true)
  }

  useEffect(() => {
    if (uploadFiles && uploadedFiles) {
      createAppointmentOpenScheduling()
    }
  }, [uploadFiles, uploadedFiles])

  return (
    <>
      <Block minWidth="94%" padding="scale600">
        <HeaderLabel />
        <FlexGrid
          flexGridColumnCount={[1, 1, 2]}
          flexGridColumnGap="scale300"
          flexGridRowGap="scale300"
          overrides={{
            Block: {
              style: {
                width: '100%',
                display: 'flex',
                itemAlign: 'center'
              }
            }
          }}>
          {/* SCHEDULER EMAIL */}
          <FlexGridItem {...itemProps}>
            <Block minWidth="100%">
              <SchedulerEmail
                schedulerEmail={schedulerEmail}
                setSchedulerEmail={setSchedulerEmail}
              />
            </Block>
          </FlexGridItem>

          {/* FACILITY SELECT */}
          <FlexGridItem {...itemProps}>
            <Block minWidth="100%">
              <FacilitySelect />
            </Block>
          </FlexGridItem>

          {facility?.appointmentPreference?.enableYardManagement && (
            <>
              {/* HANDLING METHOD */}
              <FlexGridItem
                {...itemProps}
                overrides={{
                  Block: {
                    style: {
                      marginBottom: '0px'
                    }
                  }
                }}>
                <Block minWidth="100%">
                  <ButtonGroup
                    mode="radio"
                    label={t(
                      'Appointments.CreateAppointmentModal.Fields.HandlingMethod.Label.Text'
                    )}
                    buttons={handlingMethodButtons(facility)}
                    value={handlingMethod}
                    onChange={setHandlingMethod}
                  />
                  <Divider />
                </Block>
              </FlexGridItem>

              {/* APPOINTMENT CATEGORY */}
              <FlexGridItem {...itemProps} overrides={{ Block: { style: { marginTop: '0px' } } }}>
                <Block minWidth="100%">
                  <ButtonGroup
                    mode="checkbox"
                    label={t('Scheduler.OpenScheduling.Header.AppointmentTypes.Text')}
                    buttons={appointmentTypesButtons}
                    value={appointmentDirections}
                    onChange={setAppointmentDirection}
                    visibility={handlingMethod === IS_DROP}
                  />
                  <Divider />
                </Block>
              </FlexGridItem>
            </>
          )}

          <FlexGridItem {...itemProps}>
            <Block minWidth="100%">
              {hasHandlingMethod && (
                <>
                  {!is_inbound && is_outbound ? (
                    <CustomQuestionsProvider>
                      <AppointmentForm
                        appointmentDirection={OUTBOUND_TYPE}
                        facility={facility}
                        openscheduling
                      />
                    </CustomQuestionsProvider>
                  ) : (
                    <CustomQuestionsProvider>
                      <AppointmentForm
                        appointmentDirection={INBOUND_TYPE}
                        facility={facility}
                        openscheduling
                      />
                    </CustomQuestionsProvider>
                  )}
                </>
              )}
            </Block>
          </FlexGridItem>
          <FlexGridItem {...itemProps}>
            <Block minWidth="100%" maxWidth={'96% '}>
              {is_inbound && is_outbound && handlingMethod === IS_DROP && (
                <CustomQuestionsProvider>
                  <AppointmentForm
                    appointmentDirection={OUTBOUND_TYPE}
                    facility={facility}
                    openscheduling
                  />
                </CustomQuestionsProvider>
              )}
            </Block>
          </FlexGridItem>
        </FlexGrid>
        <RowGap />
        <DateTime type={CREATE} />
        <RowGap />
        {facility.note && (
          <Block minWidth="100%" justifyContent={'flex-end'} display={'flex'}>
            <Checkbox
              checked={termsOfService.isTermsOfServiceChecked}
              onMouseUp={e => setShowTermsOfServiceModal(true)}
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'Scheduler.OpenScheduling.Steps.TermsOfService.TermsOfServiceCheckboxLabel.Text'
                    )
                  }}
                />
              }
            />
          </Block>
        )}
        <Divider width="100%" />
        <Block width={'100%'} justifyContent={'flex-end'} display={'flex'}>
          <VButton
            startEnhancer={<X size={16} />}
            disabled={shouldDisableCancelButton()}
            onClick={resetAppointmentStates}>
            {t('Common.Fields.Cancel.Text')}
          </VButton>
          <Gap />
          <ErrorMessageButton
            label={t('Scheduler.OpenScheduling.Buttons.CreateAppointment.Text')}
            errors={returnErrors()}
            disabled={
              isCreateAppointmentButtonDisabled ||
              (facility.note && !termsOfService.isTermsOfServiceChecked)
            }
            buttonProps={{
              onClick: prepareAppointment,
              type: 'submit',
              isLoading: loading,
              startEnhancer: <Check size={16} />
            }}
          />
        </Block>
      </Block>
      <TermsOfServiceModal termsOfServiceText={facility.note} />
    </>
  )
}

export const Gap = () => <Block marginRight="scale300" />
export const RowGap = () => <Block marginBottom="scale800" />

const HeaderLabel = () => {
  const { t } = useTranslation()

  return (
    <>
      <LabelLarge
        overrides={{
          Block: {
            style: ({ $theme }) => ({
              color: $theme.colors.primary
            })
          }
        }}>
        {t('Scheduler.OpenScheduling.Header.CreateAppointment.Text')}
      </LabelLarge>
      <ParagraphXSmall
        overrides={{
          Block: {
            style: ({ $theme }) => ({
              fontSize: '10px',
              color: $theme.colors.primary
            })
          }
        }}>
        {t('Scheduler.OpenScheduling.Header.RequiredInformation.Text')}
      </ParagraphXSmall>
      <RowGap />
    </>
  )
}

export default OpenSchedulingCreateAppointmentDrop
