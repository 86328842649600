import { useContext, useEffect } from 'react'
import { FlexGridItem } from 'baseui/flex-grid'
import FormControl from 'components/ui/generic/FormControl'
import { Appointment, Question } from 'components/appointments/types'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import { checkShowOnCreateProperty } from 'components/utils/questions-validations'
import React from 'react'
import Questions from '../../../components/Questions/Questions'
import formatQuestionCaption from 'components/utils/format-question-caption'
import { DOCUMENT_TYPE } from 'components/models/Question'
import Document from 'components/shared/fields/questions/document'
import { checkQuestionError } from '../../../utils/check-question-error'
import { getQuestionPermissions } from '../../../utils/get-question-permissions'
import { CurrentUserContext } from '../../../homepage/current-user-context'

interface QuestionListProps {
  appointment: Appointment
  setAppointment: Function
  userType?: String
  showErrors?: boolean
}

const QuestionsList = ({
  appointment,
  setAppointment,
  userType,
  showErrors = true
}: QuestionListProps) => {
  const {
    questions,
    answers,
    actions: { updateAnswer, updateQuestion }
  } = useCQStateValue()
  const questionsList: Array<Question> = checkShowOnCreateProperty(questions)
  const { currentUser } = useContext(CurrentUserContext)

  const handleUpdateAnswer = (question, answer) => {
    const permission = getQuestionPermissions({
      currentUser,
      userType,
      question
    })
    updateAnswer({
      questionId: question.id,
      response: answer.response,
      error: checkQuestionError(question, permission, answer)
    })
    updateQuestion({
      questionId: question.id,
      response: answer.response,
      appointmentId: appointment.id
    })
  }

  useEffect(() => {
    setAppointment?.(answers)
  }, [answers])

  return (
    <>
      {questionsList.map((question, index) => {
        const permission = getQuestionPermissions({
          currentUser,
          userType,
          question
        })

        if (
          !question.disabled &&
          permission.canView &&
          (appointment?.id || permission.showOnCreate)
        ) {
          const { answerType, prompt, documentUrl, documentName } = question
          return (
            <FlexGridItem key={index}>
              <>
                {answerType === DOCUMENT_TYPE ? (
                  <Document
                    question={question}
                    answer={answers[index]}
                    disabled={permission && !permission?.canEdit}
                    updateAnswer={updateAnswer}
                    label={prompt}
                    permission={permission}
                  />
                ) : (
                  <FormControl caption={formatQuestionCaption({ question })}>
                    <Questions
                      question={question}
                      disabled={permission && !permission?.canEdit}
                      answer={answers[index]}
                      setAnswer={answer => handleUpdateAnswer(question, answer)}
                      displayError={showErrors}
                      label={prompt}
                      permission={permission}
                    />
                  </FormControl>
                )}
              </>
            </FlexGridItem>
          )
        }
      })}
    </>
  )
}

export default QuestionsList
