import authenticatedFetch from 'components/utils/authenticated-fetch'
import http from 'components/utils/authenticated-fetch'
import { ValidateResponse } from 'components/types/orderValidationResponse'
import { ValidateOrderOpenSchedulingRequestBody, ValidateOrderResponse } from './order'

class AppointmentService {
  get = (id: string): Promise<[any, number]> =>
    http({
      path: `/appointments/${id}.json`,
      method: 'GET'
    })

  getAnsweredQuestions = (id: string): Promise<any> =>
    http({
      path: `/appointments/${id}/answered_questions.json`,
      method: 'GET'
    })

  create = (body): Promise<any> =>
    authenticatedFetch({
      path: `/appointments.json`,
      method: 'POST',
      body
    }).then(([result, status]) => [result, status])

  createOpenScheduling = (body): Promise<any> =>
    authenticatedFetch({
      path: `/appointments/create_for_open_scheduling.json`,
      method: 'POST',
      body
    }).then(([result, status]) => [result, status])

  assignDock = (appointmentId: string, dockId: string, date?: string): Promise<any> =>
    authenticatedFetch({
      path: `/appointments/assign_dock_to_appointment.json?id=${dockId}&appointment_id=${appointmentId}${
        date ? `&date=${date}` : ''
      }`,
      method: 'POST'
    }).then(([result, status]) => [result, status])

  update = (id, body): Promise<any> =>
    authenticatedFetch({
      path: `/appointments/${id}.json`,
      method: 'PATCH',
      body
    }).then(([result, status]) => [result, status])

  updateOpenScheduling = (id, body): Promise<any> =>
    authenticatedFetch({
      path: `/appointments/${id}/open_scheduling.json`,
      method: 'PATCH',
      body
    }).then(([result, status]) => [result, status])

  getUniqueIndentifiersErrors = (body: any): Promise<[ValidateResponse, number]> =>
    authenticatedFetch({
      path: `/orders/validate.json`,
      method: 'PUT',
      body
    }).then(([result, status]: [ValidateResponse, number]) => [result, status])

  getUniqueIndentifiersErrorsOpenScheduling = (
    body: ValidateOrderOpenSchedulingRequestBody
  ): Promise<[ValidateResponse, number]> =>
    authenticatedFetch({
      path: `/orders/validate/open_scheduling.json`,
      method: 'PUT',
      body
    }).then(([result, status]: [ValidateResponse, number]) => [result, status])

  getOpenScheduling = (id: string): Promise<[any, number]> =>
    http({
      path: `/appointments/${id}/get_facility_for_open_scheduling.json`,
      method: 'GET'
    })

  getAppointmentOpenScheduling = (search: string, subdomain: string): Promise<[any, number]> =>
    http({
      path: `/appointments/get_for_open_scheduling.json?limit=10&identifier=${search}&subdomain=${subdomain}`,
      method: 'GET'
    })

  getAppointmentDriver = (search: string, facilityId: string): Promise<[any, number]> =>
    http({
      path: `/appointments/get_for_drivers_checkin.json?identifier=${search}&facility_id=${facilityId}`,
      method: 'GET'
    })

  getUpcomingAppointments = ({
    signal,
    userId,
    page,
    search,
    status
  }: {
    userId: string
    signal: any
    search?: string
    status?: string[]
    page?: number
  }): Promise<[any, number]> => {
    const params = new URLSearchParams()

    if (search) {
      params.append('identifier', search)
    }
    if (page > 1) {
      params.append('page', page.toString())
    }
    if (status && status.length) {
      status.forEach(item => {
        params.append('status[]', item.toString())
      })
    }
    return authenticatedFetch({
      path: `/users/${userId}/upcoming_appointments.json?${params.toString()}`,
      signal
    })
  }
}

export const appointmentService = new AppointmentService()
