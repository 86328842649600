import { answerService } from 'components/services'

export const useFileHandler = () => {
  const updateFiles = ({
    documentSelections = [],
    uploadFiles = false,
    temporalFiles = [],
    updateFileUploadState,
    onComplete,
    answerServiceInstance = answerService
  }) => {
    try {
      // delete removed files
      if (documentSelections?.length && uploadFiles) {
        documentSelections.forEach(({ documentId, questionId }) =>
          answerServiceInstance.removeFile(documentId, questionId)
        )
      }

      if (temporalFiles?.length && uploadFiles) {
        // trigger upload of new files
        updateFileUploadState(true)
      } else if (!temporalFiles?.length && uploadFiles) {
        onComplete?.()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onFilesUploaded = ({ temporalFiles, uploadFiles, onComplete }) => {
    if (
      temporalFiles?.length &&
      uploadFiles &&
      temporalFiles.every(tf => tf?.uploadState === 'saved')
    ) {
      onComplete?.()
    }
  }

  return { updateFiles, onFilesUploaded }
}
